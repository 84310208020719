.motivosTag {
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  border: solid 0.5px;
  margin-top: 5px;
}

.motivos-span-selecionar-motivos {
  font-size: 16px;
  color: #000;
  margin-top: 10px;
  font-family: 'Montserrat';
}


@media only screen and (max-height: 635px) and (max-width: 375px) {
  .motivos-span-selecionar-motivos {
    position: relative !important;
    top: 5px !important;
    font-size: 8pt !important;
  }
}