@import url('https://fonts.googleapis.com/css2?family=Montserrat');

html,
body {
  overflow: hidden;
}

.layout-container {
  display: flex;
  flex-direction: column;
  height: 97vh !important;
}

.layout-header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.layout-footer {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(143, 143, 143);
}

.layout-component-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.layout-footer-fixo {
  position: relative;
  top: -15px;
  text-align: right !important;
  border-top: 1px solid lightgray;
}

@media only screen and (max-width: 768px) {
  /* iPad Mini */

  .layout-container {
    height: 92.5vh !important;
  }

  .layout-footer-fixo {
    top: -5px;
  }

}

@media only screen and (max-width: 605px) {

  /* Tablet Android */
  .layout-container {
    height: 88vh !important;
  }

  .layout-footer {
    top: 5px;
  }

}

@media only screen and (max-width: 500px) {
  .layout-footer {
    position: relative;
    top: 0px;
  }
}

@media only screen and (max-height: 740px) {

  /* Meu Celular */
  .layout-container {
    height: 90vh !important;
  }

  .layout-footer-fixo {
    top: 0px;
  }
}

@media only screen and (max-height: 700px) {

  /* Site / Monitor */
  .layout-container {
    height: 97vh !important;
  }

  .layout-footer-fixo {
    top: 0px;
  }
}

@media only screen and (min-height: 812px) and (min-width: 375px) {

  .layout-container {
    height: 98.5vh !important;
  }
}

@media only screen and (max-height: 553px) and (max-width: 375px) {
  .layout-container {
    height: 82.5vh !important;
  }
}


@media only screen and (max-height: 635px) and (max-width: 375px) {
  .layout-container {
    height: 82.5vh !important;
  }
}