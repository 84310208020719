@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.menu-loja {
    align-items: center;
    justify-content: space-between;
    height: 220px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    padding: 0 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 330px;
}

.menu-titulo-loja {
    font-family: 'Montserrat';
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
    cursor: default !important;
    left: -10px;
}

.menu-items-icone {
    font-size: 20px;
    margin-right: 10px;
    color: #3196F5;
}

.menu-items-label {
    font-size: 16px;
    font-family: 'Montserrat';
}


.fila-cabecalho {
    font-family: 'Montserrat';
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    padding: 0 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.fila-cabecalho img {
    position: relative;
    width: 100px;
    height: auto;
    max-width: 100%;
}

.fila-logo {
    font-size: 22px;
    font-family: 'Montserrat';
}

.logo-bluve {
    position: relative;
    left: -30px;
}

.fila-menu {
    display: flex;
    align-items: center;
}

.fila-profile {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Montserrat';
}

.fila-profile-span {
    margin-right: 10px;
    font-size: 16px;
    font-family: 'Montserrat';
    cursor: pointer;
}

.fila-profile-span-label {
    cursor: pointer;
}

.fila-dropdown-menu {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: none;
}

.fila-dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.fila-dropdown-menu ul li a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: #333;
}

.fila-dropdown-menu ul li a:hover {
    background-color: #f5f5f5;
}

.profileAvatar {
    position: relative;
    left: 10px;
    background-color: rgb(238, 237, 237);
    border-radius: 50%;
    color: rgba(2, 175, 240, 0.7);
    font-size: 1.5rem;
    text-align: center;
    font-family: 'Montserrat';
    cursor: default;
}

.menu-rodape {
    display: flex;
}

.menu-rodape-botao-sair {
    position: relative;
    width: 100%;
}

.menu-rodape-label-versao {
    display: block;
    text-align: right;
    width: 100%;
    cursor: default !important;
    font-family: 'Montserrat';
    font-size: 11px;
}

.menu-titulo-div-setor {
    margin-bottom: -10px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.menu-titulo-span-setor {
    font-size: 11px;
    font-family: 'Montserrat';
}

.company-dot {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #3196F5;
    margin-right: 10px;
    font-family: 'Montserrat';
    top: 2px;
}

.company-dot-label {
    position: relative;
    top: 4px;
}


@media only screen and (max-width: 800px) {

    .logo-bluve {
        left: -20px;
    }

    .fila-logo {
        font-size: 16px;
        font-family: 'Montserrat';
    }

    .fila-profile-span-label {
        display: none;
    }
}

@media only screen and (max-width: 605px) {

    .logo-bluve {
        left: -20px;
    }

    .fila-logo {
        font-size: 16px;
        font-family: 'Montserrat';
    }

    .fila-profile-span-label {
        display: none;
    }
}


@media only screen and (max-width: 400px) {

    .logo-bluve {
        left: 5px;
    }

}