@import url('https://fonts.googleapis.com/css2?family=Montserrat');


.steps-label-opcional {
    position: relative;
    top: -95px;
    left: -80px !important;
    font-family: 'Montserrat';
    font-size: 10pt;
    font-style: italic;
}

.steps-inputstr {
    position: relative;
    margin-bottom: 10px;
    text-align: left;
    width: 400px !important;
}

.steps-label-ou {
    width: 100%;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 14pt;
}



@media only screen and (max-width: 768px) {
    /* iPad Mini */

    .steps-label-opcional {
        left: -60px !important;
    }
}


@media only screen and (max-height: 740px) {
    /* Meu Celular */
    .steps-label-opcional {
        left: -0px !important;
    }

    .steps-inputstr {
        width: 350px !important;
    }
    
  
  }
  