@font-face {
  font-family: 'bluve';
  src:  url('fonts/bluve.eot?1ymswh');
  src:  url('fonts/bluve.eot?1ymswh#iefix') format('embedded-opentype'),
    url('fonts/bluve.ttf?1ymswh') format('truetype'),
    url('fonts/bluve.woff?1ymswh') format('woff'),
    url('fonts/bluve.svg?1ymswh#bluve') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bluve' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-people_alt:before {
  content: "\e905";
}
.icon-food:before {
  content: "\e904";
}
.icon-lunch:before {
  content: "\e904";
}
.icon-soup:before {
  content: "\e904";
}
.icon-stats-dots:before {
  content: "\e99b";
}
.icon-flag:before {
  content: "\e9cc";
}
.icon-man-woman:before {
  content: "\e9de";
}
.icon-home:before {
  content: "\e900";
}
.icon-coffee:before {
  content: "\e901";
}
.icon-street-view:before {
  content: "\e902";
}
.icon-sign-out:before {
  content: "\e903";
}
