.header-container {
    position: relative;
    border: 2px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 5px 5px;
    position: absolute;
    left: 4px;
    top: 90px;
    width: 99.4%;
    height: 100px;
    text-align: center;
}
