@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.steps-buscar-produtos {
    position: absolute !important;
    top: 1px !important;
    left: 0;
    width: 100%;
}

.steps-div-cards {
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
}

.steps-div-lista-produtos {
    height: 220px;
    overflow: auto;
    position: relative;
    top: -4px;
}

.steps-div-lista-produtos::-webkit-scrollbar {
    width: 10px;
}

.steps-div-lista-produtos::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.steps-div-lista-produtos::-webkit-scrollbar-thumb {
    background: #888;
}

.steps-div-lista-produtos::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.steps-nenhum-produto-informado {
    font-size: 14px;
    font-family: 'Montserrat';
    font-style: italic;
    text-align: center;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

}

@media only screen and (min-height: 923px) {

    .steps-div-lista-produtos {
        height: 470px;
    }
}

@media only screen and (max-height: 795px) {

    /* Meu monitor! Cuidado com outras configurações neste caso! */

    .steps-div-lista-produtos {
        height: 220px;
    }

}

@media only screen and (max-height: 625px) {

    .steps-div-lista-produtos {
        height: 220px;
    }

}


@media only screen and (max-width: 400px) {

    .steps-div-lista-produtos {
        height: 220px !important;
    }

}

