@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

.header {
    position: absolute;
    width: 100%;
    height: 300px;
    left: 0px;
    top: 0px;
    background: linear-gradient(116.73deg, #02AFF0 2%, #5D21D2 178.06%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header img {
    width: 130px;
    height: auto;
    margin-top: -200px;
}

.arrow {
    position: absolute;
    width: 200px;
    height: 100%;
    left: 10px;
    top: -37px;
    border-top: 169.355px solid transparent;
    border-bottom: 169.355px solid transparent;
    border-left: 100px solid #02AFF0;
}

.arrow::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20px;
    width: 20px;
    background: linear-gradient(to right, rgba(2, 175, 240, 0.7), rgba(2, 175, 240, 0));
}

.arrow::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 10px;
    background: linear-gradient(to right, rgba(2, 175, 240, 0.7), rgba(2, 175, 240, 0));
}

.arrow span {
    display: block;
    width: 5px;
    height: 5px;
    margin: 3px;
    border-radius: 50%;
    background: #02AFF0;
    float: left;
}

.arrow span:nth-child(1),
.arrow span:nth-child(2),
.arrow span:nth-child(3),
.arrow span:nth-child(4),
.arrow span:nth-child(5),
.arrow span:nth-child(6),
.arrow span:nth-child(7),
.arrow span:nth-child(8),
.arrow span:nth-child(9),
.arrow span:nth-child(10),
.arrow span:nth-child(11),
.arrow span:nth-child(12),
.arrow span:nth-child(13),
.arrow span:nth-child(14),
.arrow span:nth-child(15) {
    opacity: 1;
}

.arrow span:nth-child(16),
.arrow span:nth-child(17),
.arrow span:nth-child(18),
.arrow span:nth-child(19),
.arrow span:nth-child(20),
.arrow span:nth-child(21),
.arrow span:nth-child(22),
.arrow span:nth-child(23),
.arrow span:nth-child(24),
.arrow span:nth-child(25) {
    opacity: 0.5;
}

.telaMeio {
    box-sizing: border-box;
    position: absolute;
    width: 496px;
    height: 430px;
    left: 50%;
    top: 50%;
    box-shadow: 0px 12px 48px rgba(0, 67, 197, 0.12);
    border-radius: 8px;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 1;
    overflow: hidden;
    justify-content: center;
    word-break: break-word;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.textoLogin {
    position: absolute;
    width: 100%;
    top: 50px;
    transform: translateY(-50%);
    height: 90px;
    left: 0;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 140%;

    text-align: center;
    color: #4A5684;
}

.login-form {
    max-width: 300px;
    position: relative;
    top: 57%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.EsqueciSenha {
    float: right;
    color: white !important;
    text-decoration: underline !important;
}

.recaptcha {
    margin-bottom: 2rem;
}

@media only screen and (max-width: 600px) {
    .centerLogin {
        width: 80% !important;
        height: 60% !important;
        position: relative;
    }

    .login-form .ant-input {
        width: 250px;
        margin-left: 20px;
    }


    .ant-form-item-control-input {
        width: 250px !important;
        margin-left: 20px !important;
    }

    .login-form .ant-input-prefix {
        margin-left: 20px;
    }

    .login-form .ant-input-suffix {
        margin-right: 20px;
    }

    .EsqueciSenha {
        position: relative !important;
        margin-right: 10px;
    }

    .textoLogin {
        width: 70%;
        left: 15%;
    }
}

/* 

.centerLogin {
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 30%;
    height: 70%;
    text-align: center;

    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.83);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.83);
    box-shadow: rgba(0, 0, 0, 0.83) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.83) 0px 18px 36px -18px inset;
}



.titleLoginH1 {
    font-size: 48px;
    font-family: 'Poppins-Bold';
    color: white;
    position: relative;
    top: 10px;
}

.titleLoginH6 {
    font-size: 18px;
    font-family: 'Poppins-Bold';
    position: relative;
    top: -20px;
    color: white;
}

.login-form {
    max-width: 300px;
    position: relative;
    top: 30%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.EsqueciSenha {
    float: right;
    color: white !important;
    text-decoration: underline !important;
}

.bodyLogin .ant-form-explain {
    color: yellow !important;
} */