@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.floating-action-button {
    position: relative;
    bottom: 24px;
    right: 24px;
    display: flex;
    align-items: flex-end;
}

.floating-action-button__options {
    position: absolute;
    right: -240px;
    /* Ajuste conforme necessário para controlar a distância dos botões em relação ao botão inicial */
    bottom: 0;
    display: flex;
    flex-direction: row;
    gap: 8px;
    top: 19px !important;
    transform: translateX(-186%);
    /* Adiciona o deslocamento lateral */
}

.btn-adicionar-atendimento {
    position: relative;
    margin-right: 10px;
    color: #1890ff;
    height: 50px;
    width: 50px;
    top: 23px;
}

.btn-adicionar-atendimento-icone {
    position: relative;
    font-size: 40px;
    top: -5px
}

.action-botao-venda {
    background-color: #52c41a;
    height: 50px !important;
    width: 50px !important;
}

.action-botao-venda:hover {
    background-color: #52c41a;
}

.action-botao-nao-venda {
    background-color: #ff4d4f;
    height: 50px !important;
    width: 50px !important;
}

.action-botao-nao-venda:hover {
    background-color: #ff4d4f;
}


.action-botao-troca {
    background-color: #1890ff;
    height: 50px !important;
    width: 50px !important;
}

.action-botao-troca:hover {
    background-color: #1890ff;
}

.action-botao-outros {
    background-color: #807702;
    height: 50px !important;
    width: 50px !important;
}

.action-botao-outros:hover {
    background-color: #807702;
}

.action-botao-adicionar-atendimento {
    background-color: #f5c504;
    height: 50px !important;
    width: 50px !important;
}

.action-botao-adicionar-atendimento:hover {
    background-color: #f5c504;
}

.botao-acao-icone {
    position: relative;
    font-size: 28px;
    color: white;
    left: 10px;
    top: -2px;
}

.botao-acao-label {
    position: relative;
    top: 33px;
    left: -40px;
    color: black;
    font-family: 'Montserrat';
    font-style: italic;
}

.floating-action-button-button {
    background-color: #ff4d4f;
    height: 40px !important;
    top: 20px;
    position: relative;
}


@media only screen and (max-width: 768px) {
    /* iPad Mini */

    .btn-adicionar-atendimento {
        top: 23px;
    }

    .floating-action-button__options {
        transform: translateX(-212%);
        /* Adiciona o deslocamento lateral */
    }
}

@media only screen and (max-width: 605px) {

    /* Tablet Android */
    .floating-action-button__options {
        transform: translateX(-186%);
        /* Adiciona o deslocamento lateral */
    }

    .btn-adicionar-atendimento {
        top: 28px;
    }


}

@media only screen and (max-height: 740px) {

    /* Meu Celular */

    .floating-action-button__options {
        transform: translateX(-148%);
        /* Adiciona o deslocamento lateral */
    }
}

@media only screen and (max-height: 700px) and (max-width: 1860px) {
    /* Meu monitor! Cuidado com outras configurações neste caso! */

    .floating-action-button__options {
        transform: translateX(-415px);
    }

}

@media only screen and (max-height: 795px) {

    /* Meu monitor! Cuidado com outras configurações neste caso! */


}

@media only screen and (min-height: 812px) and (min-width: 375px) {
    .floating-action-button__options {
        transform: translateX(-340px);
    }
}

@media only screen and (max-height: 553px) and (max-width: 375px) {
    .floating-action-button__options {
        transform: translateX(-340px);
    }
}

@media only screen and (max-height: 635px) and (max-width: 375px) {
    .floating-action-button__options {
        transform: translateX(-340px);
    }
}