@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.list-item-meta-avatar {
    margin-left: 10px;
}

.fila-vendedores-disponiveis {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}


.adicionar-vendedores-empty-container .ant-empty-image svg {
    color: #3196f5;
    vertical-align: middle;
    margin-bottom: 10px;
}


.adicionar-vendedores-empty-container-strong {
    color: #4A5684;
    font-family: 'Montserrat';
    font-size: 16px;
}

.adicionar-vendedores-empty-container-description {
    color: #4A5684;
    font-family: 'Montserrat';
    font-size: 14px;
}

.div-vendedores-waiting-load {
    position: relative;
    top: 100px;
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    text-align: center;
}


.fila-vendedores-disponiveis .sellerBadge {
    color: #40a9ff;
    margin-right: 1rem;
}

.btnAdicionarAtendimento {
    width: 2.5rem !important;
    height: 2.5rem !important;
    top: -5px
}

.btnAdicionarAtendimento .iconAdd {
    font-size: 2.188rem;
    line-height: 0;
    text-align: center;
}

.btnSuccess {
    background-color: rgb(76, 177, 76);
    color: white;
    height: 50px;
    width: 100px;
}


.btnSuccess:hover {
    background-color: rgb(76, 177, 76);
    color: white;
}

.btnIniciarAtendimento {
    margin-left: 10px;
    height: 40px;
    color: white;
}

.btnEncerrarAtendimento {
    margin-left: 10px;
    margin-right: 15px;
    height: 40px;
    width: 180px;
    color: white;
}

.lista-vendedores-content {
    height: auto;
}

.fila-vendedores-disponiveis-container {
    position: absolute;
    width: 100%;
    margin-bottom: 1000px !important;
}

@media only screen and (max-width: 768px) {

    /* iPad Mini */
    .lista-vendedores-content {
        height: 648px;
    }
}

@media only screen and (max-width: 605px) {

    /* Tablet Android */
    .lista-vendedores-content {
        height: 487px;
    }
}

@media only screen and (max-height: 740px) {

    /* Meu Celular */
    .lista-vendedores-content {
        height: 415px;
    }
}


@media only screen and (max-height: 700px) {

    /* Site / Monitor */
    .lista-vendedores-content {
        height: 327px;
    }
}

@media only screen and (max-width: 400px) {

    .btnEncerrarAtendimento {
        width: 100px;
    }
}