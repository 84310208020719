@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.component {
  position: relative;
  display: flex;
  height: 1.00rem;
  width: 6.00rem;
  top: 10px
}

.text {
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 0.7rem;
  margin: 0.375rem 0.375rem 0;
  font-family: 'Montserrat';
  color: lightgray;
  position: relative;
  top: -3px
}

.text>p {
  margin-bottom: 0;
  position: relative;
  width: auto;
  white-space: nowrap;
}

.help {
  margin-left: 1rem;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  color: #3196F5;
}

.help:hover {
  color: grey;
  transition: color 0.5s;
}


@media only screen and (max-width: 480px) {
  .component {
    height: 0.80rem;  
    width: 3.50rem;
  }
  
  /* .text {
    display: none;
  } */
/* 
  .help {
    display: none;
  } */
} 