
.fora-mix-span-selecionar {
  font-size: 16px;
  color: #000;
  margin-top: 10px;
  font-family: 'Montserrat';
}


@media only screen and (max-height: 635px) and (max-width: 375px) {
  .fora-mix-span-selecionar {
    position: relative !important;
    top: 5px !important;
    font-size: 8pt !important;
  }
}